.button {
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #090909;
    padding: 0.7em 1.7em;
    cursor: pointer;
    font-size: 18px;
    font-family:"AssistantR";
    border-radius: 0.5em;
    background: #ffb590;
    margin:2% auto;
    border:none;
    box-shadow:  0 0 5px 1px #515151;
  }
  

  
  .button:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .button:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #df8152;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  

  .button:hover:before {
    top: -35%;
    background-color: #df8152;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
  .button:hover:after {
    top: -45%;
    background-color: #df8152;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }