.title{
    font-size:4rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    direction:rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    direction: rtl;
    margin:2% auto;
}
.center{
    display: flex;
    justify-content: center;
    
}

.subTitle{
    font-size:2.5rem;
    font-family: "Assistant";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:90%;
}



.description{
    font-size:1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin:3% auto 0 ;
    direction: rtl;
    width:70%;
}

@media only screen and (max-width: 350px) {
    .title { font-size: 2.5rem; margin: 3% auto; }
    .row { flex-direction: column; justify-content: center;
    display: flex; align-items: center;
    }
    .subTitle { font-size: 1.5rem; width: 95%; margin:2% auto;

    }
    .description { font-size: 1.2rem; width: 90%; margin:25px auto 0 }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title { font-size: 2.8rem; margin: 2.5% auto; }
    .row { flex-direction: column; }
    .subTitle { font-size: 1.8rem; width: 95%; }
    .description { font-size: 1.25rem; width: 85%; margin:25px auto 0  }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title { font-size: 3rem; margin: 2% auto; }
    .subTitle { font-size: 2.2rem; width: 95%; }
    .description { font-size: 1.3rem; width: 80%; margin:25px auto 0  }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title { font-size: 3.2rem; margin: 1.8% auto; }
    .subTitle { font-size: 2.3rem; width: 95%; }
    .description { font-size: 1.32rem; width: 75%; }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
    .title { font-size: 3.4rem; margin: 1.6% auto; }
    .subTitle { font-size: 2.4rem; width: 92%; }
    .description { font-size: 1.34rem; width: 72%; }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
    .title { font-size: 3.6rem; margin: 1.4% auto; }
    .subTitle { font-size: 2.45rem; width: 91%; }
    .description { font-size: 1.36rem; width: 71%; }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
    .title { font-size: 3.8rem; margin: 1.2% auto; }
    .subTitle { font-size: 2.47rem; width: 90%; }
    .description { font-size: 1.38rem; width: 70%; }
}

@media only screen and (min-width: 950px) and (max-width: 1050px) {
    .title { font-size: 3.9rem; margin: 1.1% auto; }
    .subTitle { font-size: 2.48rem; width: 90%; }
    .description { font-size: 1.39rem; width: 70%; }
}

@media only screen and (min-width: 1050px) and (max-width: 1250px) {
    .title { font-size: 4rem; margin: 1% auto; }
    .subTitle { font-size: 2.5rem; width: 90%; }
    .description { font-size: 1.4rem; width: 70%; }
}