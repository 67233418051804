.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  justify-content: center;
  margin: 0 auto;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

}

.rowRight {
  flex-direction: row;
  
}

.rowLeft {
  flex-direction: row-reverse;
}

.content {
  flex: 1;
  padding: 0 20px;
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 200px;
  object-fit: contain;
}

.title {
  font-size: 1.5rem;
  font-family: "Assistant";
  margin: 0 auto;
direction: rtl;
}

.description {
  font-size: 1.1rem;
  font-family: "AssistantR";
  margin: 0 auto;
  direction: rtl;
}

.rightAligned {
  text-align: right;
  
}

.leftAligned {
  text-align: right;
}

@media (max-width: 768px) {
  .row, .rowRight, .rowLeft {
    flex-direction: column;
    margin: 20px auto;
  }

  .content {
    text-align: center;
    padding: 10px 0;
    width:90%;
  }

}