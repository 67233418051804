.title{
    font-size:4rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    direction:rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    direction: rtl;
    margin:2% auto;
}
.center{
    display: flex;
    justify-content: center;
    width:20%;
    margin:1% auto;
}
.image{
    width: 100%;
object-fit: contain;
border-radius: 30px 30px 30px 100px ;
box-shadow: 0 0 5px 1px #222;
}
.subTitle{
    font-size:2.5rem;
    font-family: "Assistant";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:90%;
}



.description{
    font-size:1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:70%;
}
@media only screen and (max-width: 350px) {
    .title { font-size: 2.5rem;
    margin:2% auto;
    }
    .row { flex-direction: column; }
    .center { width: 70%; 
        margin:2% auto;}
    .subTitle { font-size: 2rem; width: 95%;margin:2% auto; }
    .description { font-size: 1.1rem; width: 95%; }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title { font-size: 2.8rem;  margin:2% auto; }
    .row { flex-direction: column; }
    .center { width: 65%;   margin:2% auto;}
    .subTitle { font-size: 2rem; width: 95%; }
    .description { font-size: 1.15rem; width: 90%; }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title { font-size: 3rem; }
    .center { width: 55%;  margin:2% auto;}
    .subTitle { font-size: 2.5rem; width: 95%; }
    .description { font-size: 1.2rem; width: 85%; }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title { font-size: 3.2rem; }
    .center { width: 45%; margin:2% auto; }
    .subTitle { font-size: 2.7rem; width: 95%; }
    .description { font-size: 1.25rem; width: 85%; }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
    .title { font-size: 3.4rem; }
    .center { width: 40%; }
    .subTitle { font-size: 3rem; width: 95%; }
    .description { font-size: 1.3rem; width: 85%; }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
    .title { font-size: 3.6rem; }
    .center { width: 35%; }
    .subTitle { font-size: 3.2rem; width: 90%; }
    .description { font-size: 1.35rem; width: 75%; }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
    .title { font-size: 3.8rem; }
    .center { width: 30%; }
    .subTitle { font-size: 2.8rem; width: 90%; }
    .description { font-size: 1.35rem; width: 80%; }
}

@media only screen and (min-width: 950px) and (max-width: 1050px) {
    .title { font-size: 3.9rem; }
    .center { width: 25%; }
    .subTitle { font-size: 2.48rem; width: 90%; }
    .description { font-size: 1.39rem; width: 70%; }
}

@media only screen and (min-width: 1050px) and (max-width: 1250px) {
    .title { font-size: 4rem; }
    .center { width: 22%; }
    .subTitle { font-size: 2.5rem; width: 90%; }
    .description { font-size: 1.4rem; width: 70%; }
}