.cube {
    width: 300px;
    height: 100px;
    border-radius: 25px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
    display: flex;
 
    margin: 2%;
    background: #FDF5E6; /* צבע לבן קרם */
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all ease 0.3s;
  }

  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 2;
  }
  
  .title {
    text-align: center;
    font-size: 18px;
    font-family: "Assistant";
    color: #333;
    width:90%;
  }
  
  .square {
    position: absolute;
    top: -25px; /* חצי בפנים חצי בחוץ */
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    background-color: #F57C00; /* צבע כתום כהה */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    border-radius: 50%; /* עיגול מלא */
  }
  
  .checkmark {
    font-size: 24px;
    color: white;
    z-index: 4;
  }
  @media only screen and (max-width: 850px){
    .cube {
        width: 300px;
        height: 100px;
        border-radius: 25px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
        display: flex;
     
        margin: 25px;
        background: #FDF5E6; /* צבע לבן קרם */
        justify-content: center;
        align-items: center;
        position: relative;
        transition: all ease 0.3s;
      }
    
      
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        z-index: 2;
      }
      
      .title {
        text-align: center;
        font-size: 18px;
        font-family: "Assistant";
        color: #333;
        width:90%;
      }
      
      .square {
        position: absolute;
        top: -25px; /* חצי בפנים חצי בחוץ */
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 50px;
        background-color: #F57C00; /* צבע כתום כהה */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
        border-radius: 50%; /* עיגול מלא */
      }
      
      .checkmark {
        font-size: 24px;
        color: white;
        z-index: 4;
      }


  }