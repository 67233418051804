.NewBox {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 2% auto;
  box-shadow: 0 0 5px 1px #8686868b;
  transition: box-shadow 2s ease-in-out;
  animation: shadowPulse 2s infinite;
  padding: 10px;
  background: linear-gradient(135deg, #f5e8e4 0%, #f0d8d0 100%);
  border-radius: 10px 20px 10px 20px;
  position: relative;
  z-index: -1;
}
.row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@keyframes shadowPulse {
  0%, 100% {
    box-shadow: 0 0 5px 1px #8686868b;
  }
  50% {
    box-shadow: 0 0 5px 3px #8686868b;
  }
}

.description {
  font-size: 1.1rem;
  font-family: "AssistantR";
  text-align: right;
  margin: 2% auto;
  direction: rtl;
  width: 90%;
  text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
  color: black;
}

.title {
  font-size: 2rem;
  font-family: "Assistant";
  text-align: right;
padding-right: 10px;
  direction: rtl;
  width: 100%;
  text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
  color: black;
}

.icon {
  display: flex;
  width: 30%;
  margin-left: auto;
  position: relative;
  z-index: -1;
}

@media only screen and (max-width: 350px) {
  .NewBox {
    width: 280px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 3% auto;
  }
  .description {
    font-size: 1rem;
    font-family: "AssistantR";
    text-align: right;
    margin: 2% auto;
    direction: rtl;
    width: 90%;
    text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
    color: black;
  }
  .title {
    font-size: 1.8rem;
    font-family: "Assistant";
    text-align: right;
    margin: 3% auto;
    direction: rtl;
    width: 60%;
    text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
    color: black;
  }
  .icon {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    margin: 2% auto;
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .NewBox {
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 3% auto;
  }
  .description {
    font-size: 1.1rem;
    font-family: "AssistantR";
    text-align: right;
    margin: 2% auto;
    direction: rtl;
    width: 90%;
    text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
    color: black;
  }
  .title {
    font-size: 2rem;
    font-family: "Assistant";
    text-align: right;
    margin: 3% auto;
    direction: rtl;
    width: 60%;
    text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
    color: black;
  }
  .icon {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    margin: 2% auto;
  }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  .NewBox {
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 3% auto;
  }
  .description {
    font-size: 1.15rem;
    font-family: "AssistantR";
    text-align: right;
    margin: 2% auto;
    direction: rtl;
    width: 95%;
    text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
    color: black;
  }
  .title {
    font-size: 2.2rem;
    font-family: "Assistant";
    text-align: right;
    margin: 3% auto;
    direction: rtl;
    width: 60%;
    text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
    color: black;
  }
  .icon {
    display: flex;
    justify-content: flex-end;
    width: 25%;
    margin: 2% auto;
  }
}
