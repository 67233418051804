.title{
    font-size:4rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    direction:rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    direction: rtl;
    margin:2% auto;
}

.subTitle{
    font-size:2.5rem;
    font-family: "Assistant";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:40%;
}



.description{
    font-size:1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:50%;
}
.bold{
    font-size:1.4rem;
    font-family: "Assistant";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:70%;
}
@media only screen and (max-width: 350px) {
    .title {
        font-size: 2.2rem;
    }
    .subTitle {
        font-size: 2rem;
        width: 90%;
    }
    .description {
        font-size: 1.1rem;
        width: 95%;
    }
    .bold {
        font-size: 1.2rem;
        width: 90%;
        line-height: 1.4;
    }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2.5rem;
    }
    .subTitle {
        font-size: 2.5rem;
        width: 80%;
    }
    .description {
        font-size: 1.15rem;
        width: 85%;
    }
    .bold {
        font-size: 1.25rem;
        width: 85%;
        line-height: 1.4;
    }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 3.3rem;
    }
    .subTitle {
        font-size: 2.8rem;
        width: 70%;
    }
    .description {
        font-size: 1.25rem;
        width: 85%;
    }
    .bold {
        font-size: 1.35rem;
        width: 90%;
    }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 3.5rem;
    }
    .subTitle {
        font-size: 2.5rem;
        width: 60%;
    }
    .description {
        font-size: 1.3rem;
        width: 90%;
    }
    .bold {
        font-size: 1.35rem;
        width: 85%;
    }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 3.7rem;
    }
    .subTitle {
        font-size: 2.4rem;
        width: 50%;
    }
    .description {
        font-size: 1.35rem;
        width: 85%;
    }
    .bold {
        font-size: 1.35rem;
        width: 75%;
    }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3.8rem;
    }
    .subTitle {
        font-size: 3rem;
        width: 85%;
    }
    .description {
        font-size: 1.4rem;
        width: 85%;
    }
    .bold {
        font-size: 1.5rem;
        width: 75%;
    }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
   
    .subTitle {
        font-size: 3.2rem;
        width: 42%;
    }
    .description {
        font-size: 1.4rem;
        width: 80%;
    }
    .bold {
        font-size: 1.6rem;
        width: 75%;
    }
}

@media only screen and (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 3.95rem;
    }
    .subTitle {
        font-size: 3.5rem;
        width: 41%;
    }
    .description {
        font-size: 1.4rem;
        width: 70%;
    }
    .bold {
        font-size: 1.6rem;
        width: 70%;
    }
}
@media only screen and (min-width: 1050px) and (max-width: 1250px) {

    .subTitle {
        font-size: 3.5rem;
        width: 41%;
    }
    .description {
        font-size: 1.4rem;
        width: 60%;
    }
    .bold {
        font-size: 1.7rem;
        width: 70%;
    }
}